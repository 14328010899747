import { useNavigate } from "react-router-dom";
import { useState, useRef, useEffect, useContext } from "react";
import { AppContext } from "../App.js";
import { Lobby } from "./Lobby.js";
import Cookies from "universal-cookie";
import {
  getDatabase,
  ref,
  set,
  push,
  hasChild,
  exists,
  get,
  serverTimestamp,
} from "firebase/database";
import { getAuth, onAuthStateChanged, GoogleAuthProvider } from "firebase/auth";
import { db, auth, uid } from "../firebase-config.js";
import { signInAnonymously, updateProfile } from "firebase/auth";
import "../styles/Auth.css";
import '../styles/Auth-grid.css';

import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMemo } from "react";
import { createAvatar } from "@dicebear/core";
import { avataaars, lorelei } from "@dicebear/collection";
import { getRandomBoolean } from "../utilities/helperFunctions.js";


export const Auth = ({ shouldJoin, setShouldJoin }) => {
  const [isAuth, setIsAuth] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  let localRoomID;
  const roomInputRef = useRef(null);
  let navigate = useNavigate();

  const schema = yup.object().shape({
    name: yup.string().required("Please write a Nickname"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const signIn = async (data) => {
    setIsButtonDisabled(true); // Deaktiviert den Button nach dem ersten Klick
    try {
      const result = await signInAnonymously(auth);
      await updateProfile(auth.currentUser, {
        displayName: data.name,
      });
      setIsAuth(true);
      createRoom();
    } catch (err) {
      console.error(err);
      setIsButtonDisabled(false); // Falls ein Fehler auftritt, den Button wieder aktivieren
    }
  };

  async function createRoom() {
    try {
      const newRoomRef =  push(ref(db, "rooms/"));
      const newRoomData = {
        lastActivity: serverTimestamp(),
        active: true,
        players: {
          [uid]: {
            isCreator: true,
            playerName: auth.currentUser.displayName,
            lives: 3,
            online: true,

          },
        },
        questions: {
          question: "",
          correctAnswer: "",
          playerAnswer: "",
        },
        status: {
          hasStarted: false,
          playerNumber: 1,
        },
        settings: {
          lives: 3,
          rounds: 4,
          questionTime: 30,
          votingTime: 20,
        },
      };
      await set(newRoomRef, newRoomData);
      setShouldJoin(false);
      navigate(`/room/${newRoomRef.key}`);

      return newRoomRef.key;
    } catch (error) {
      console.error(error);
      setIsButtonDisabled(false); // Falls ein Fehler auftritt, den Button wieder aktivieren
      throw new Error("Failed to create room");
      setErrorMessage(error.message);

    }
  }
  return (
    <div className="auth">
  <h1 className="auth-heading">Kick the Fool</h1>
  <h5 className="inspiration-note">
  inspired by the YouTubers 
  <a href="https://www.youtube.com/@Reved" target="_blank" rel="noopener noreferrer"> Reved</a> and 
  <a href="https://www.youtube.com/@pietsmiet" target="_blank" rel="noopener noreferrer"> Pietsmiet</a>
</h5>
  <form className="auth-form" onSubmit={handleSubmit(signIn)}>
    <div className="input-container">
      <input
        className="auth-input"
        maxLength="14"
        placeholder="type name..."
        {...register("name")}
        disabled={isButtonDisabled} // Deaktiviert das Eingabefeld während der Ausführung
      />
<span className="tooltip-icon" style={{ fontSize: '34px' }}>?</span>
<div className="tooltip-text">
         Kick the Fool is a free multiplayer web game where players compete in a series of rounds, in which every player has to answer a question. After several rounds, players vote on which answer they find the most stupid (all in good humor). The player with the most votes loses a life.

      </div>
    </div>
    <p className="auth-error">{errors.name?.message || errorMessage}</p>
    <button
      className="auth-button"
      type="submit"
      disabled={isButtonDisabled} // Button wird deaktiviert, wenn isButtonDisabled true ist
    >
      create Room
    </button>
  </form>

  <div className="info-links">
    <a href="/contact" className="info-link">Contact</a>
    <a href="/terms-of-service" className="info-link">Terms of Service</a>
    <a href="/credits" className="info-link">Credits</a>
  </div>
</div>
  );
  
};
