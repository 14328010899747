import { db, auth, uid } from "../firebase-config.js";
import {
  onValue,
  getDatabase,
  ref,
  set,
  push,
  hasChild,
  exists,
  get,
  serverTimestamp,
  off,
  remove,
  onDisconnect
} from "firebase/database";
import { AuthJoin } from "./AuthJoin";
import { VolumeSlider } from "./sections/VolumeSlider";
import { getPlayerById, getFirstDifferentPlayerIndex, creatorExists} from "../utilities/helperFunctions.js";
import { Game } from "./Game";
import { useState, useRef, useEffect, useContext } from "react";
import {onAuthStateChanged} from "firebase/auth"

import { AppContext } from "../App";
import "../styles/Lobby.css";
import "../styles/Lobby-grid.css";
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import { SoundControl } from "./sections/LastAnswer";

import { timer,initializePlayerLives } from "../utilities/gameFunctions";
import { createAvatar } from "@dicebear/core";
import { avataaars , lorelei } from "@dicebear/collection";
import { dylan } from '@dicebear/collection';


import { useMemo } from "react";

export const Lobby = ({volume, listener1Ref, setVolume, roomID, setInLobby, isCreator, lives, rounds,setRounds, setIsCreator, questionTime,votingTime,playerNumber,setPlayerNumber, players ,setPlayers, shouldJoin, setShouldJoin, language, setLanguage, setLives, setQuestionTime, setVotingTime, listener4Ref
}) => {

  //const { shouldJoin, setShouldJoin } = useContext(AppContext);
  const [isAuth, setIsAuth] = useState(false);
  const [hasStarted, setHasStarted] = useState(false);
  const [count, setCount] = useState(6.0);
  const [startedAt, setStartedAt] = useState(null);
  let timeLeft = 3;
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [currentUid, setCurrentUid] = useState(uid);
  const currentUidRef = useRef(currentUid);


  // for onDisconnect variables
  const prevPlayerRef = useRef(null);
  const prevCreaterRef = useRef(null);
  const shouldJoinRef = useRef(shouldJoin);


  

  useEffect(() => {
    shouldJoinRef.current = shouldJoin;
  }, [shouldJoin]);
  

  useEffect(() => {
    currentUidRef.current = currentUid;
    if (currentUid) {
      if (prevPlayerRef.current) {
        onDisconnect(prevPlayerRef.current).cancel();
      }
      const playerRef = ref(db, "rooms/" + roomID + "/players/" + currentUid);
      onDisconnect(playerRef).remove(); 
      prevPlayerRef.current = playerRef; 
    }
    return () => {
      if (prevPlayerRef.current) {
          console.log("cancel")
         onDisconnect(prevPlayerRef.current).cancel();
      }
    }
  }, [currentUid]);
  

  
 


  useEffect(() => {
    if(listener4Ref.current){
      window.removeEventListener('popstate', listener4Ref.current);

    }

    // onDisconnects werden nur beim zurück navigieren getrigered wenn man von der Seite runter navigiert
    // um auch handere zurück pfeile zu handeln dieses zeug
    // popstate wird allerdings auch bei vorwärtspfeil getrigered was mögliche buggs verursacht
    // man braucht listener1Ref um in einem anderen Component diesen listener canceln zu können
    // man kann nicht in diesem komponent canceln da sonst beim zurück pfeil gecancelt wird bevor handePopstate ausgeführt wird
    function handlePopState1() {

      remove(ref(db, "rooms/" + roomID + "/players/" + currentUidRef.current));
      setShouldJoin(true);
    }

    listener1Ref.current = handlePopState1;
    
    // Füge den Event-Listener hinzu
    window.addEventListener('popstate', handlePopState1);

    /*
    const testRef1 = ref(db, "rooms/" + roomID + "/players/" + currentUid);
    const testRef2 = ref(db, "rooms/" + roomID + "/players/" + currentUid + "/isCreator");
    
    const disconnect1 = onDisconnect(testRef1);
    const disconnect2 = onDisconnect(testRef2);
  
    disconnect1.remove();
    disconnect2.set(true);
    */
    // Hier sicherstellen, dass cancel() nur für testRef2 verwendet wird, 
    // wenn keine anderen wichtigen onDisconnect-Operationen auf testRef1 sind.
    //disconnect2.cancel();

 
      // Variablen für die Unsubscribe-Funktionen
    let unsubscribePlayersListener;
    let unsubscribeLivesListener;
    let unsubscribeRoundsListener;
    let unsubscribeQuestionTimeListener;
    let unsubscribeVotingTimeListener;
    let unsubscribeHasStartedListener;

    // mache alles erst wenn user authenzifiert ist um probleme mut security rules zu umgehen
    const unsubscribeonAuthStateChanged = onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUid(user.uid);
 


    
    const livesRef = ref(db, "rooms/" + roomID + "/settings/lives");
    

    //console.log(count);
    //console.log(shouldJoin);
    // join Prozess
    if (shouldJoinRef.current) {
      setIsCreator(true);
      // console.log("jetzt aber ich will schlafen")
    }


    
      const playersRef = ref(db, "rooms/" + roomID + "/players");
      unsubscribePlayersListener = onValue(playersRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const playersArray = Object.entries(data).map(([playerID, playerData]) => {
            const votedBy = playerData.votedBy ? Object.keys(playerData.votedBy) : [];
            return { playerID: playerID, ...playerData, votedBy: votedBy };
          });
          setPlayers(playersArray);
          setPlayerNumber(playersArray.length);
          if(playersArray.length > 1){
            set(ref(db, "rooms/" + roomID + "/status/playerNumber"), playersArray.length);
          }
          if(getPlayerById(uid, playersArray).isCreator){
            setIsCreator(false);
          }

          // um sicherzu stellen das wenn ein Creator leavt, das ein neuer ausgewählt wird
          // ist super innefektiv da jeder spieler diesen set setzt
          // potential zum optimieren in zukunft
          // das darunter raus kommentierte wäre besser aber klappt nicht weil:
          // https://stackoverflow.com/questions/78928214/unexpected-behavior-with-firebase-realtime-database-ondisconnect-and-cancel-in-n

          // man muss sortieren da sonst manchmal bugg wenn gleicher name
          const sortedPlayers = playersArray.sort((a, b) => a.playerID.localeCompare(b.playerID));
          if (!creatorExists(sortedPlayers)) {
              set(ref(db, "rooms/" + roomID + "/players/" + sortedPlayers[0].playerID + "/isCreator"), true);
          }

          // if player isCreator setze onDisconnect neuen Creator
          /*
          if(getPlayerById(uid, playersArray).isCreator) {
            setIsCreator(false);

            if (prevCreaterRef.current) {
              console.log("cancle: " + prevCreaterRef.current)
              onDisconnect(prevCreaterRef.current).cancel();
            }
            
            const newCreatorID = playersArray[getFirstDifferentPlayerIndex(uid, playersArray)].playerID
            const newCreaterRef = ref(db, "rooms/" + roomID + "/players/" + newCreatorID + "/isCreator");
            console.log("setze on Disconnect: "+ newCreaterRef)

            onDisconnect(newCreaterRef).set(true);
            prevCreaterRef.current = newCreaterRef;            
          } 
          */
      

        }
      });

    // sync game Settings
    //const livesRef = ref(db, "rooms/" + roomID + "/settings/lives"); // Pfad zum ausgewählten Feld in der Realtime Database
    unsubscribeLivesListener = onValue(livesRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setLives(data); // Aktualisiere den Wert im State mit dem Wert aus der Datenbank
      }
    });
    const roundsRef = ref(db, "rooms/" + roomID + "/settings/rounds"); // Pfad zum ausgewählten Feld in der Realtime Database
    unsubscribeRoundsListener = onValue(roundsRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setRounds(data); // Aktualisiere den Wert im State mit dem Wert aus der Datenbank
      }
    });
    const questionTimeRef = ref(db, "rooms/" + roomID + "/settings/questionTime"); // Pfad zum ausgewählten Feld in der Realtime Database
     unsubscribeQuestionTimeListener = onValue(questionTimeRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setQuestionTime(data); 

      }
    });
    const votingTimeRef = ref(db, "rooms/" + roomID + "/settings/votingTime");
    unsubscribeVotingTimeListener = onValue(votingTimeRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setVotingTime(data);

      }
    });
    const languageRef = ref(db, "rooms/" + roomID + "/settings/language");
    const unsubscribeLanguageListener = onValue(languageRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setLanguage(data);

      }
    });
    const hasStartedRef = ref(db, "rooms/" + roomID + "/status/hasStarted");
    unsubscribeHasStartedListener = onValue(hasStartedRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {


        if(!shouldJoinRef.current){
          setInLobby(false); 
        }



      }
    });



    if (!isCreator) {
        // entferne damit es nicht beim rendern gelesen, wird.
        // könnte auc hdurch ein isInitialLoad geregelt werden 
        // möglichkeit zum minimieren von firebase use 
       remove(ref(db, `rooms/${roomID}/status/backToLobby`))
    }
    }
  });


        // Cleanup function to remove the listener when the component unmounts
//ALARM WARUM NICHT GECALLED?
    return () => {
      unsubscribePlayersListener();
      unsubscribeLivesListener();
      unsubscribeRoundsListener();
      unsubscribeQuestionTimeListener();
      unsubscribeVotingTimeListener();
      unsubscribeHasStartedListener();
      //window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  // erstelle avatare
  // https://www.dicebear.com/styles/avataaars/
  const avatars = useMemo(() => {
    return players ? players.map((player) =>
      createAvatar(dylan , {
        size: 50,
        seed: player.playerName,
        backgroundColor: []
      }).toDataUri()
    ) : [];
  }, [players]);

  /*
  useEffect(() => {
    if(playerNumber > 1){
      set(ref(db, "rooms/" + roomID + "/status/playerNumber"), playerNumber);
    }
  }, [playerNumber]);
  */

  // bei release das davor packen https://
  const copyToClipboard = () => {
    const copyText = `https://${window.location.hostname}/room/${roomID}`;
    navigator.clipboard.writeText(copyText);
  };

  const handleLivesChange = (e) => {
    set(ref(db, "rooms/" + roomID + "/settings/lives"), e.target.value);
    setLives(e.target.value);
  };

  const handleRoundsChange = (e) => {
    set(ref(db, "rooms/" + roomID + "/settings/rounds"), e.target.value);
    setRounds(e.target.value);
  };

  const handleQuestionTimeChange = (e) => {
    set(ref(db, "rooms/" + roomID + "/settings/questionTime"), e.target.value);
    setQuestionTime(e.target.value);
  };

  const handleVotingTimeChange = (e) => {
    set(ref(db, "rooms/" + roomID + "/settings/votingTime"), e.target.value);
    setVotingTime(e.target.value);
  };

  const handleLanguageChange = (e) => {
    set(ref(db, "rooms/" + roomID + "/settings/language"), e.target.value);
    setLanguage(e.target.value);
  };

  
  const handleGameStart  = async (e) => {
    setIsButtonDisabled(true);
    if(!isCreator){
      await initializePlayerLives (roomID, lives, players, db);
      set(ref(db, "rooms/" + roomID + "/lastActivity"), serverTimestamp());
      set(ref(db, "rooms/" + roomID + "/status/hasStarted"), true);
    }
  };

  // mit uid anstat isAuth ist eigentlic besser
  if (shouldJoin) {
    return (
      <div>
        <AuthJoin roomID={roomID} setIsAuth={setIsAuth} shouldJoin={shouldJoin} setShouldJoin={setShouldJoin} />
      </div>
    );
  }

  if (!shouldJoin) {
    return (
      <div className="lobby-container">
        <div className="Lobby-settings-box">
        <VolumeSlider className="Lobby-VolumeSlider" volume = {volume} setVolume ={setVolume} />

          <h1 className="lobby-title">Game Settings</h1>
          <form id="gameSettingsForm">
            <label>Lives:</label>
            <select
              id="livesInput"
              name="lives"
              required
              disabled={isCreator}
              value={lives}
              onChange={handleLivesChange}
            >
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="10">10</option>
            </select>
            <br />
            <label>Rounds until Voting:</label>
            <select
              id="roundsInput"
              name="rounds"
              required
              disabled={isCreator}
              value={rounds}
              onChange={handleRoundsChange}
            >
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
            </select>
            <br />
            <label>Time per Question:</label>
            <select
              id="timePerQuestionInput"
              name="timePerQuestion"
              required
              disabled={isCreator}
              value={questionTime}
              onChange={handleQuestionTimeChange}
            >
              <option value="5">5s</option>
              <option value="10">10s</option>
              <option value="20">20s</option>
              <option value="30">30s</option>
              <option value="40">40s</option>
              <option value="50">50s</option>
              <option value="60">60s</option>
              <option value="90">90s</option>
              <option value="120">120s</option>
              <option value="150">150s</option>
              <option value="180">180s</option>
            </select>
            <br />
            <label>Voting Time:</label>
            <select
              id="Voting Time Input"
              name="Voting Time"
              required
              disabled={isCreator}
              value={votingTime}
              onChange={handleVotingTimeChange}
            >
              <option value="10">10s</option>
              <option value="20">20s</option>
              <option value="30">30s</option>
              <option value="40">40s</option>
              <option value="50">50s</option>
              <option value="60">60s</option>
              <option value="90">90s</option>
              <option value="120">120s</option>
              <option value="150">150s</option>
              <option value="180">180s</option>
            </select>
            <br />
            <label>Language:</label>
            <select
              id="languageInput"
              name="languageInput"
              required
              disabled={isCreator}
              value={language}
              onChange={handleLanguageChange}
            >
              <option value="english">English</option>
              <option value="german">German</option>
            </select>
            <br />

          </form>
                  {!isCreator && (
          <button
            className="lobby-start-button"
            onClick={handleGameStart}
            disabled={isCreator || playerNumber < 3 ||  isButtonDisabled}
          >
            Start
          </button>
)}
        </div>
        <p className="Lobby-playernumber">{playerNumber}/12</p>
          {playerNumber < 3 && (
          <p className="Lobby-minimal-playernumber">You need at least 3 players to start the game.</p>
        )}
      <div className="Lobby-player-list">
        {players && players.map((player, index) => (
          <div className="Lobby-player" key={player.playerID}>
            <img src={avatars[index]} alt="Avatar" />
            <h3 className="Lobby-player-name" style={{ position: "relative" }}>
              {player.playerID === uid ? (
                <span style={{ backgroundColor: "yellow", display: "inline-block", padding: "0 5px",   }}>
                  {player.playerName}
                  {player.isCreator && (<span style={{ position: "absolute", top: "-20px", left: "-5px", fontSize: "17px",  }}>
                    👑
                  </span>
                  )}
                </span>
              ) : (
                  <span style={{ position: "relative", display: "inline-block" }}>
                    {player.playerName}
                    {player.isCreator && 
                    (<span style={{ position: "absolute", top: "-20px", left: "-5px", fontSize: "17px",  }}>
                    👑
                    </span>
                    )}
                  </span>
              )}
            </h3>
          </div>
        ))}
      </div>

        <div className="Lobby-invitation-link">
          <p>
          Invitation link: https://{window.location.hostname}:3000/room/
            {roomID}
          </p>
          <button onClick={copyToClipboard}>Copy link</button>
        </div>
      </div>
    );
  }

};
