import "../styles/WinnerScreen-grid.css";

import {db, auth, uid, } from "../firebase-config.js";
import { createAvatar } from "@dicebear/core";
import { avataaars , lorelei, dylan } from "@dicebear/collection";
import { VolumeSlider } from "./sections/VolumeSlider";

import { useEffect,useState,  useMemo, useRef } from "react";
import { renderBrains, resetRoom  } from "../utilities/helperFunctions.js";
import {onValue, set, ref, onDisconnect, remove, serverTimestamp} from "firebase/database";
import { getPlayerById, creatorExists, removeAllOfflinePlayers, getAlivePlayers} from "../utilities/helperFunctions.js";

export const WinnerScreen = ({
  volume, setVolume,
  winner1,
  winner2,
  setInLobby,
  setShowWinnerScreen,
  setShowVoting,
  roomID,
  lives,
  rounds,
  questionTime,
  votingTime,
  isCreator,
  setVotingNumber,
  setIsCreator,
  setPlayers,
  players,
  listener3Ref,
  listener4Ref,
  setShouldJoin
,
  }) => {
    
    const [backToLobby, setBackToLobby] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const playersRef = useRef(players);
    const isCreatorRef = useRef(isCreator);


        // for onDisconnect variables

    const [currentUid, setCurrentUid] = useState(uid);

    const prevPlayerRef = useRef(null);
  
    useEffect(() => {
      isCreatorRef.current = isCreator;
    }, [isCreator]);


    useEffect(() => {
      playersRef.current = players;
    }, [players]);
  
    useEffect(() => {
      if (currentUid) {
        if (prevPlayerRef.current) {
          onDisconnect(prevPlayerRef.current).cancel();
        }
        const playerRef = ref(db, "rooms/" + roomID + "/players/" + currentUid);
        onDisconnect(playerRef).remove(); 
        prevPlayerRef.current = playerRef; 
      }
      return () => {
        if (prevPlayerRef.current) {
           onDisconnect(prevPlayerRef.current).cancel();
        }
      }
    }, [currentUid]);


    useEffect(() => {  
      if(listener3Ref.current){
        window.removeEventListener('popstate', listener3Ref.current);
  
      }

          // onDisconnects werden nur beim zurück navigieren getrigered wenn man von der Seite runter navigiert
        // um auch handere zurück pfeile zu handeln dieses zeug
        // popstate wird allerdings auch bei vorwärtspfeil getrigered was mögliche buggs verursacht
        // man braucht listener1Ref um in einem anderen Component diesen listener canceln zu können
        // man kann nicht in diesem komponent canceln da sonst beim zurück pfeil gecancelt wird bevor handePopstate ausgeführt wird
      function handlePopState4() {

        remove(ref(db, "rooms/" + roomID + "/players/" + currentUid));
        setShowWinnerScreen(false);
        setInLobby(true);
        setShouldJoin(true);
      }
  
      listener4Ref.current = handlePopState4;
      
      // Füge den Event-Listener hinzu
      window.addEventListener('popstate', handlePopState4);


      const playersRef = ref(db, "rooms/" + roomID + "/players");
      const unsubscribePlayersListener = onValue(playersRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const playersArray = Object.entries(data).map(([playerID, playerData]) => {
            const votedBy = playerData.votedBy ? Object.keys(playerData.votedBy) : [];
            return { playerID: playerID, ...playerData, votedBy: votedBy };
          });
          setPlayers(playersArray);
          if(getPlayerById(uid, playersArray).isCreator){
            setIsCreator(false);
          }

          // man muss sortieren da sonst manchmal bugg wenn gleicher name
          //ne sortieren kann man eigentlich lassen
          const alivePlayers = getAlivePlayers(playersArray);
          const sortedPlayers = alivePlayers.sort((a, b) => a.playerID.localeCompare(b.playerID));
          if (!creatorExists(sortedPlayers)) {
              set(ref(db, "rooms/" + roomID + "/players/" + sortedPlayers[0].playerID + "/isCreator"), true);
          }



        }
      });

      const backToGameRef = ref(db, "rooms/" + roomID + "/status/backToLobby");
      const unsubscribeBackToLobbyListener = onValue(backToGameRef, (snapshot) => {
        const data = snapshot.val();
        if(data){
          
          setBackToLobby(data); 
        }
      });
     
      setInLobby(true); 
      setShowVoting(false);
      setVotingNumber(1);

      // Cleanup function to remove the listener when the component unmounts
    return () => {

      unsubscribeBackToLobbyListener();

    };
    }, []);

      const avatar1 = useMemo(() => {
        return(
          createAvatar(dylan, {
            size: 70,
            seed: winner1.playerName,
            backgroundColor: []
          }).toDataUri()
        )
      }, [winner1]);
      


      const avatar2 = useMemo(() => {
        return(
          createAvatar(dylan, {
            size: 70,
            seed: winner2.playerName,
            backgroundColor: []

          }).toDataUri ()
        )
      }, [winner2]);
      
      useEffect(() => {
        if(backToLobby){
          set(ref(db, "rooms/" + roomID + "/lastActivity"), serverTimestamp());
          setShowWinnerScreen(false);
        }
      }, [backToLobby]);

      const transitionToLobby = async () => {
        setIsButtonDisabled(true);
         if (!isCreatorRef.current) {
          // reset firebase
          await resetRoom(roomID,lives,rounds,questionTime,votingTime);
          if(playersRef.current){
            removeAllOfflinePlayers(playersRef.current, roomID);
          }
          //reset variables

          
          set(ref(db, "rooms/" + roomID + "/status/backToLobby"), true);
        }  
      }

    return (
   <div className='wrapper'>
    <div className='glow'></div>
    <div className='mask'>
     <div className="WinnerScreen-container">
     <VolumeSlider className="WinnerScreen-VolumeSlider" Lobby-settings-box= {volume} volume = {volume} setVolume ={setVolume} />
     <span className="trophy-emoji1">🏆</span>
     <div className=" Winning-Player1">
     <img src={avatar1} alt="Avatar" />
              {winner1.playerID === uid ? (
                  <h3 className="Winner1-name">
                  <span style={{ backgroundColor: "yellow", display: "inline-block", padding: "0 5px" }}>
                    {winner1.playerName}
                  </span>
                  </h3>
                ) : (
                  <h3 className="Lobby-player-name">{winner1.playerName}</h3>
                )} 
     </div>
     <span className="trophy-emoji2">🏆</span>
     <div className=" Winning-Player2">
     <img src={avatar2} alt="Avatar" />
              {winner2.playerID === uid ? (
                  <h3 className="Winner2-name">
                  <span style={{ backgroundColor: "yellow", display: "inline-block", padding: "0 5px" }}>
                    {winner2.playerName}
                  </span>
                  </h3>
                ) : (
                  <h3 className="Lobby-player-name">{winner2.playerName}</h3>
                )} 
     </div>
            <button disabled={isCreator || isButtonDisabled} className="Back-to-Lobby-button" onClick={transitionToLobby}>back to lobby</button>
     </div>
    </div>
  </div>


    )
  }