import { useState, useRef, useEffect, createContext } from "react";
import { signOut } from "firebase/auth";
import { browserHistory } from 'react-router';

import { Lobby } from "./components/Lobby";
import { RoomDeleted } from "./components/RoomDeleted";
import { Voting } from "./components/Voting";

import { Game } from "./components/Game";
import { enableLogging } from "firebase/database";

import { Auth } from "./components/Auth.js";
import Cookies from "universal-cookie";
import { db, auth, uid } from "./firebase-config.js";
import {
  getDatabase,
  ref,
  set,
  push,
  hasChild,
  exists,
  get,
  remove,
  goOffline ,
  onValue,
} from "firebase/database";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { WinnerScreen } from "./components/WinnerScreen.js";
import { useNavigate, useLocation } from 'react-router-dom';

export const AppContext = createContext();


/*

{
  "rules": {
    "rooms": {
      "$roomID": {
        ".write": "auth != null && (data.child('players').child(auth.uid).exists() || !data.exists() || newData.child('players').child(auth.uid).exists())",
        ".read": "auth != null"
      }
    }
  }
}


*/

/*
const deleteAllRooms = async () => {
  try {
    const roomsRef = ref(db, 'rooms');
    const snapshot = await get(roomsRef);
    if (snapshot.exists()) {
      const rooms = snapshot.val();
      for (const roomID in rooms) {
        if (rooms.hasOwnProperty(roomID)) {
          await remove(ref(db, `rooms/${roomID}`));
        }
      }
    } else {
    }
  } catch (error) {
    console.error(error);
  }
};

// Beispiel: Lösche alle Räume
deleteAllRooms();
*/
function App() {

  const [shouldJoin, setShouldJoin] = useState(true);
  const [showVoting, setShowVoting] = useState(false);
  const [inLobby, setInLobby] = useState(true); 
  const [showWinnerScreen, setShowWinnerScreen] = useState(false); 
 





  return (
    <div className="App">
      <AppContext.Provider >
        <Router>
          <Routes>
            <Route path="/" element={<Auth shouldJoin={shouldJoin} setShouldJoin={setShouldJoin} />} />
          <Route path="/room/:id" element={<Room showVoting={showVoting}  shouldJoin={shouldJoin} setShouldJoin={setShouldJoin} setShowVoting={setShowVoting} inLobby={inLobby} setInLobby={setInLobby} showWinnerScreen={showWinnerScreen} setShowWinnerScreen={setShowWinnerScreen}/> } />
          <Route path="/room-deleted" element={<RoomDeleted />} />

          </Routes>
        </Router>
      </AppContext.Provider>
    </div>
  );
}

function Room({ showWinnerScreen, setShowWinnerScreen, showVoting, setShowVoting, inLobby, setInLobby, shouldJoin, setShouldJoin, }) {
  const url = window.location.pathname;

  const parts = url.split("/");
  const roomID = parts[2];
  const [isCreator, setIsCreator] = useState(false);
  const [lives, setLives] = useState(3);
  const [rounds, setRounds] = useState(4);
  const [questionTime, setQuestionTime] = useState(30);
  const [votingTime, setVotingTime] = useState(20);
  const [playerNumber, setPlayerNumber] = useState(0);
  const [players, setPlayers] = useState([]);
  const [volume, setVolume] = useState(50);

  const [winners, setWinners] = useState([]);

  const [language, setLanguage] = useState("german");
  const [votingNumber, setVotingNumber] = useState(1);
  const listener1Ref = useRef(null);
  const listener2Ref = useRef(null);
  const listener3Ref = useRef(null);
  const listener4Ref = useRef(null);


  const navigate = useNavigate();

  useEffect(() => {
    const activeRef = ref(db, `rooms/${roomID}/active`);  
    const unsubscribe = onValue(activeRef, (snapshot) => {
      const active = snapshot.val();
      console.log("onValue trigered with active: "+ active )
      if (active === "deleted" || active === null) {
        navigate("/room-deleted", { active: { message: "The room no longer exists or was closed due to inactivity." } });
      }
    });
    return () => unsubscribe();
  }, []);






  return (
    <div>
      {showWinnerScreen ? (
        <WinnerScreen
        setIsCreator={setIsCreator}
        setPlayers={setPlayers}
        players={players}
        volume = {volume} 
        setVolume ={setVolume}
        winner1={winners[0]}
        winner2={winners[1]}
        setInLobby={setInLobby}
        setShowWinnerScreen = {setShowWinnerScreen}
        setShowVoting={setShowVoting}
        roomID={roomID}
        lives={lives}
        rounds={rounds}
        questionTime={questionTime}
        votingTime={votingTime}
        isCreator={isCreator}
        setVotingNumber={setVotingNumber}
        listener3Ref = {listener3Ref}
        listener4Ref = {listener4Ref}
        setShouldJoin={setShouldJoin}


      />
      ) : inLobby? (
        <Lobby setInLobby={setInLobby}
        volume = {volume} 
        listener4Ref = {listener4Ref}

        listener1Ref = {listener1Ref}
        setVolume ={setVolume}
        isCreator={isCreator}
        setIsCreator={setIsCreator}
        lives={lives}
        setLives={setLives}
        rounds={rounds}
        setRounds={setRounds}
        questionTime={questionTime}
        setQuestionTime={setQuestionTime}
        votingTime={votingTime}
        setVotingTime={setVotingTime}
        playerNumber={playerNumber}
        roomID={roomID}
        setPlayerNumber={setPlayerNumber}
        players={players}
        setPlayers={setPlayers}
        shouldJoin={shouldJoin}
        setShouldJoin={setShouldJoin}
        language={language}
         setLanguage={setLanguage}
        /> 

      ) : showVoting ? (
        <Voting
        setShouldJoin={setShouldJoin}
        setInLobby={setInLobby}

        listener2Ref = {listener2Ref}
        listener3Ref = {listener3Ref}

        setIsCreator={setIsCreator}

        volume = {volume} 
        setVolume ={setVolume}
        setWinners = {setWinners}
        setShowWinnerScreen = {setShowWinnerScreen}
        showVoting = {showVoting}
        setShowVoting={setShowVoting}
        votingTime={votingTime} 
        isCreator={isCreator}
        players={players}
        votingNumber={votingNumber} 
        roomID={roomID} 
        setPlayers={setPlayers}
        setVotingNumber={setVotingNumber}

        />
      ) : (
        <Game
        setInLobby={setInLobby}

        setShouldJoin={setShouldJoin}

        listener1Ref = {listener1Ref}
        listener2Ref = {listener2Ref}

        setShowWinnerScreen = {setShowWinnerScreen}
        setWinners = {setWinners}
        language = {language}
        volume = {volume} 
        setVolume ={setVolume}
        setShowVoting={setShowVoting}
        isCreator={isCreator}
        setIsCreator={setIsCreator}
        lives={lives}
        rounds={rounds}
        questionTime={questionTime}
        votingTime={votingTime}
        playerNumber={playerNumber}
        players={players}
        db={db}
        roomID={roomID}
        setPlayers={setPlayers}
        votingNumber={votingNumber}
        setVotingNumber={setVotingNumber}
        />
      )}
    </div>
  );
}

export default App;
