import React, { useState } from "react";
import { FaVolumeUp, FaVolumeMute } from "react-icons/fa"; // Lautsprecher- und Mute-Icon von FontAwesome

export const VolumeSlider = ({volume, setVolume}) => {
   // Initiale Lautstärke auf 50%
  const [muted, setMuted] = useState(false); // Zustand für stummgeschaltet
  const [hovered, setHovered] = useState(false); // Zustand für stummgeschaltet

  const handleVolumeChange = (e) => {
    setMuted(false);
    console.log(e.target.value)
    if(e.target.value == 0.0){
      setMuted(true);
    }
    setVolume(e.target.value);
  };

  const toggleMute = () => {
    setMuted(true);
    setVolume(0); // Wenn gemutet, wird die Lautstärke auf 0 gesetzt
  };

  return (
    <div 
      style={{
        position: "relative", 
        padding: "20px", 
        textAlign: "center", 
        display: "flex", 
        width: "175px",
        alignItems: "center", 
        justifyContent: "center"
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <div onClick={toggleMute} style={{ cursor: "pointer" }}>
        {muted ? (
          <FaVolumeMute style={{position: "relative", right: "95px", width: "25px", height: "25px", }} />
        ) : (
          <FaVolumeUp style={{position: "relative", right: "95px", width: "25px", height: "25px", }} />
        )}
      </div>
      {hovered && (
        <input
          type="range"
          min="0"
          max="100"
          value={volume} // Wenn gemutet, wird der Slider auf 0 gesetzt
          onChange={handleVolumeChange}
          style={{
            position: "absolute", // Slider absolut positionieren
            
            width: "150px", 
            height: "4px",
           
          }} 
        />
      )}
    </div>
  );
};
