
import { signInAnonymously,signOut, updateProfile } from "firebase/auth";
import '../styles/Auth.css';
import '../styles/Auth-grid.css';

import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup'
import { useNavigate } from "react-router-dom";
import {Auth} from "./Auth";
import {Lobby} from "./Lobby";
import Cookies from 'universal-cookie';
import { getDatabase, ref, set, push, hasChild, exists,get, update  } from "firebase/database";
import {getAuth,onAuthStateChanged, GoogleAuthProvider} from "firebase/auth"
import {db, auth, uid,} from '../firebase-config.js';
import { useState, useRef, useEffect, useContext } from "react";
import {AppContext} from "../App"

export const AuthJoin = (props) => {

  const maxPlayerNumber = 12;
  const [isButtonDisabled, setIsButtonDisabled] = useState(false); // Neuer State für die Button-Aktivierung
  const [errorMessage, setErrorMessage] = useState(""); // Nur ein State für alle Fehler


  const schema = yup.object().shape({
    name: yup.string().required("Please write a Nickname"),
  });

  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
  });

  async function joinRoom() {
    try {
      // Überprüfe, ob der Raum existiert
      const roomRef = ref(db, `rooms/${props.roomID}`);
      const roomSnapshot = await get(roomRef);
      if (roomSnapshot.val() === null) {
        throw new Error("Room does not exist");
      }

      // Überprüfe, ob der Benutzer bereits im Raum ist
      const playersRef = ref(db, `rooms/${props.roomID}/players`);
      const playersSnapshot = await get(playersRef);
      if (playersSnapshot.hasChild(uid)) {
        throw new Error("Already in room");
      }

      // Überprüfe, ob die maximale Anzahl von Spielern erreicht ist
      const roomData = roomSnapshot.val();
      if (roomData.status.playerNumber >= maxPlayerNumber) {
        throw new Error("Max player limit reached");
      }

      // Überprüfe, ob das Spiel bereits gestartet wurde
      if (roomData.status.hasStarted) {
        throw new Error("Game already started");
      } 

  
    

      // Füge den Benutzer dem Raum hinzu
        try {
          await set(ref(db, `rooms/${props.roomID}/players/${uid}`), {
            isCreator: false,
            playerName: auth.currentUser.displayName,
            lives: 3,
            online: true,
          });
          console.log("Daten erfolgreich gespeichert");
        } catch (error) {
          console.error("Fehler beim Speichern der Daten:", error);
          // Hier kannst du zusätzliche Fehlerbehandlung einfügen, z.B. eine Fehlermeldung anzeigen oder alternative Aktionen durchführen
        }


      // Aktualisiere die playerNumber im Raum
      const newPlayerNumber = roomData.status.playerNumber + 1;
      await update(ref(db, `rooms/${props.roomID}/status`), {
        playerNumber: newPlayerNumber,
      });

      // Benachrichtige die UI, dass der Prozess abgeschlossen ist
      props.setShouldJoin(false);
    } catch (error) {
      setErrorMessage(error.message);
      setIsButtonDisabled(false); // Button wieder aktivieren, wenn ein Fehler auftritt
    }
  }

  const signIn = async (data) => {
    setIsButtonDisabled(true); // Deaktiviert den Button nach dem ersten Klick
    try {
      const result = await signInAnonymously(auth);
      await updateProfile(auth.currentUser, {
        displayName: data.name,
      });

      props.setIsAuth(true);
      joinRoom();
    } catch (err) {
      console.error(err);
      setIsButtonDisabled(false); // Button wieder aktivieren, wenn ein Fehler auftritt
    }
  }

  return (
    <div className="auth">
       <h1 className="auth-heading">Kick the Fool</h1> 
      <h5 className="inspiration-note">
        inspired by the YouTubers 
        <a href="https://www.youtube.com/@Reved" target="_blank" rel="noopener noreferrer"> Reved</a> and 
        <a href="https://www.youtube.com/@pietsmiet" target="_blank" rel="noopener noreferrer"> Pietsmiet</a>
      </h5>
    <form className="auth-form" onSubmit={handleSubmit(signIn)}>
    <div className="input-container">

      <input
        className="auth-input"
        maxLength="14"
        placeholder="type name..."
        {...register("name")}
        disabled={isButtonDisabled} // Deaktiviert das Eingabefeld während der Ausführung
      />
        <span className="tooltip-icon" style={{ fontSize: '34px' }}>?</span>
<div className="tooltip-text">
         Kick the Fool is a free multiplayer web game where players compete in a series of rounds, in which every player has to answer a question. After several rounds, players vote on which answer they find the most stupid (all in good humor). The player with the most votes loses a life.

      </div>
      </div>

      <p className="auth-error">{errors.name?.message || errorMessage}</p>
      <button
        className="auth-button"
        type="submit"
        disabled={isButtonDisabled} // Button wird deaktiviert, wenn isButtonDisabled true ist
      >
        join
      </button>
    </form>
    <div className="info-links">
    <a href="/contact" className="info-link">Contact</a>
    <a href="/terms-of-service" className="info-link">Terms of Service</a>
    <a href="/credits" className="info-link">Credits</a>
  </div>
    </div>
  );
}